import * as React from "react"

export default function GatsbyWordpressLogo() {
  return (
    <svg
      width="82"
      height="24"
      viewBox="0 0 82 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.333 0C5.73301 0 0.333008 5.4 0.333008 12C0.333008 18.6 5.73301 24 12.333 24C18.933 24 24.333 18.6 24.333 12C24.333 5.4 18.933 0 12.333 0ZM5.64729 18.6857C3.84729 16.8857 2.90444 14.4857 2.90444 12.1714L12.2473 21.4286C9.84729 21.3429 7.44729 20.4857 5.64729 18.6857ZM14.3902 21.1714L3.16158 9.94286C4.10444 5.74286 7.87586 2.57143 12.333 2.57143C15.5044 2.57143 18.2473 4.11429 19.9616 6.42857L18.6759 7.54286C17.2187 5.57143 14.9044 4.28571 12.333 4.28571C8.99015 4.28571 6.16158 6.42857 5.04729 9.42857L14.9044 19.2857C17.3902 18.4286 19.2759 16.2857 19.8759 13.7143H15.7616V12H21.7616C21.7616 16.4571 18.5902 20.2286 14.3902 21.1714Z"
        fill="currentColor"
      />
      <path
        d="M45.3128 8.6268C45.5081 8.43154 45.508 8.11496 45.3128 7.9197L45.0799 7.68681C44.8846 7.49154 44.5681 7.49154 44.3728 7.68681L41.3532 10.7064C41.158 10.9016 40.8414 10.9016 40.6461 10.7064L37.6266 7.6868C37.4313 7.49154 37.1147 7.49154 36.9195 7.68681L36.6866 7.9197C36.4913 8.11496 36.4913 8.43154 36.6866 8.62681L39.7061 11.6464C39.9014 11.8416 39.9014 12.1582 39.7061 12.3535L36.6866 15.373C36.4913 15.5683 36.4913 15.8849 36.6866 16.0801L36.9195 16.313C37.1147 16.5083 37.4313 16.5083 37.6266 16.313L40.6461 13.2935C40.8414 13.0982 41.158 13.0982 41.3532 13.2935L44.3728 16.313C44.5681 16.5083 44.8846 16.5083 45.0799 16.313L45.3128 16.0801C45.508 15.8849 45.5081 15.5683 45.3128 15.373L42.2932 12.3535C42.098 12.1582 42.098 11.8416 42.2932 11.6464L45.3128 8.6268Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M59.3721 11.9996C59.3721 16.0743 61.7401 19.5957 65.1739 21.2644L60.2633 7.81006C59.6921 9.09034 59.3721 10.5072 59.3721 11.9996Z"
        fill="currentColor"
      />
      <path
        d="M76.6161 11.4803C76.6161 10.2081 76.1591 9.32698 75.7671 8.64119C75.2453 7.79322 74.7562 7.07512 74.7562 6.22715C74.7562 5.28085 75.4739 4.39997 76.4848 4.39997C76.5305 4.39997 76.5738 4.40565 76.6182 4.40819C74.7867 2.73027 72.3466 1.70581 69.6666 1.70581C66.0702 1.70581 62.9061 3.55102 61.0654 6.34585C61.307 6.3531 61.5346 6.35819 61.7279 6.35819C62.8047 6.35819 64.4714 6.22754 64.4714 6.22754C65.0264 6.19483 65.0918 7.00989 64.5374 7.07551C64.5374 7.07551 63.9798 7.14113 63.3592 7.17365L67.108 18.3242L69.3608 11.5677L67.7569 7.17326C67.2026 7.14074 66.6774 7.07512 66.6774 7.07512C66.1227 7.0426 66.1877 6.19444 66.7425 6.22715C66.7425 6.22715 68.4425 6.3578 69.4541 6.3578C70.5306 6.3578 72.1976 6.22715 72.1976 6.22715C72.7529 6.19444 72.8181 7.0095 72.2636 7.07512C72.2636 7.07512 71.7047 7.14074 71.0854 7.17326L74.8055 18.2394L75.8324 14.8081C76.2774 13.3843 76.6161 12.3616 76.6161 11.4803Z"
        fill="currentColor"
      />
      <path
        d="M69.8465 12.9001L66.7578 21.8752C67.68 22.1463 68.6553 22.2946 69.6659 22.2946C70.8647 22.2946 72.0143 22.0874 73.0844 21.7111C73.0568 21.667 73.0317 21.6202 73.0111 21.5693L69.8465 12.9001Z"
        fill="currentColor"
      />
      <path
        d="M78.6987 7.06079C78.743 7.3887 78.7681 7.7407 78.7681 8.11934C78.7681 9.16397 78.573 10.3383 77.9853 11.8066L74.8408 20.8981C77.9013 19.1134 79.9598 15.7977 79.9598 11.9999C79.96 10.2102 79.5028 8.52716 78.6987 7.06079Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.666 11.9997C57.666 5.38302 63.0494 0 69.6661 0C76.2826 0 81.6666 5.38302 81.6668 11.9997C81.6668 18.6172 76.2826 24 69.6661 24C63.0494 24 57.666 18.6172 57.666 11.9997ZM58.2162 11.9997C58.2162 18.3134 63.3528 23.45 69.6661 23.45C75.9792 23.45 81.1154 18.3134 81.1154 11.9997C81.1154 5.68647 75.9792 0.55026 69.6661 0.55026C63.3526 0.55026 58.2162 5.68647 58.2162 11.9997Z"
        fill="currentColor"
      />
    </svg>
  )
}